* {
    --header-background-color: hsla(0, 0%, 100%, 0.75);
    --box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%),
        0 1px 6px -1px rgb(0 0 0 / 2%),
        0 2px 4px 0 rgb(0 0 0 / 2%)
}

.hardware-acceleration {
    /**模拟浏览器硬件加速**/
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    transform: rotateZ(360deg);
    transform: translate3d(0, 0, 0);
}

/* 顶部样式 */
.header-div {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 0 32px;
    background-color: var(--header-background-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: blur(10px)
}

/* 顶部菜单样式 */
.header-menu {
    background: none;
    justify-content: flex-end;
    align-items: center;
    line-height: 64px;
    border: none;
    font-weight: 500;
}

.content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    min-height: 500px;
    overflow: hidden;
}

.homepage-slogan {
    align-items: center;
    padding: 64px 32px;
}

.homepage-slogan span {
    font-size: 52px;
    font-weight: bold;
    color: #ffffff;
}

.homepage-title {
    align-items: center;
    padding: 0px 32px 24px 32px;
}

.homepage-title span {
    font-size: 36px;
    font-weight: bold;
}

.air-bubble {
    background-Color: #ffffff30;
    border-Radius: 100px;
    position: absolute;
    z-Index: -1;
    backdrop-Filter: blur(10px)
}

@media screen and (max-width:960px) {
    .homepage-slogan span {
        font-size: 42px;
    }
}

@media screen and (max-width:720px) {
    .homepage-slogan span {
        font-size: 36px;
    }
}