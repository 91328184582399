/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "阿里巴巴普惠体 2.0 65 Medium";
  font-weight: 500;
  src: url("//at.alicdn.com/wf/webfont/3MSgjAfsFc7U/ItLI03QrEm7zpKz6UR5sv.woff2") format("woff2"),
    url("//at.alicdn.com/wf/webfont/3MSgjAfsFc7U/yvL3n0HuozT6rMRjzUEI_.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 900;
  font-family: 'AliPuHui';
  src: url('//at.alicdn.com/wf/webfont/exMpJIukiCms/Gsw2PSKrftc1yNWMNlXgw.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/exMpJIukiCms/vtu73by4O2gEBcvBuLgeu.woff') format('woff');
  font-display: swap;
}

* {
  font-size: 14px;
  font-weight: calc(450);
  -webkit-user-drag: none;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-family:
    "阿里巴巴普惠体 2.0 65 Medium",
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}